import React, { useEffect, useState } from "react";
import { Search } from "../../../components/search/search";
import { DataTable } from "../../../components/data-table";
import { PreviligesDataGridCols } from "./data-grid-cols";
import { AddPrevilige } from "./add-previlige";
import { LookupService } from "../../../app/core/lookup.service";
import toast from "react-hot-toast";

export const Prevliges = () => {
  const [loading, setLoading] = useState(false);
  const [previligesData, setPreviligesData] = useState<any>([]);
  const [originalPreviligesData, setOriginalPreviligesData] = useState<any>([]);
  const [searchText, setSearchText] = useState("");
  const [canShowForm, setCanShowForm] = useState(false);
  const [editData, setEditData] = useState(false);
  const [selectedData, setSelectedData] = useState<any>();

  useEffect(() => {
    loadFeatureGroups();
  }, []);


  const loadFeatureGroups = async () => {
    setLoading(true);
    try {
      const featureGroups = await LookupService.getPrevilige();
      setLoading(false);
      setPreviligesData(featureGroups.data); 
      setOriginalPreviligesData(featureGroups.data);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const handleInput = (value: string) => {
    setSearchText(value);
    if (value.trim() === "") {
      setPreviligesData(originalPreviligesData);
    } else {
      const filtered = originalPreviligesData.filter(
        (item: any) =>
          item.name.toLowerCase().includes(value.toLowerCase()) ||
          item.code.toLowerCase().includes(value.toLowerCase())
      );
      setPreviligesData(filtered);
    }
  };

  // const handleClear = () => {
  //   setSearchText("");
  //   loadFeatureGroups();
  // };

  const onActions = (data: any) => {
    if (data.type === "edit") {
      console.log(data.data);
      setEditData(true);
      setSelectedData(data.data);
    }
  };

  const closeAddPreviliage = () => {
    setCanShowForm(false);
    loadFeatureGroups();
  };

  const closeUpdatePreviliage = () => {
    setEditData(false);
    loadFeatureGroups();
  };
  return (
    <div className="container-fluid content-bg-color">
      {canShowForm && (
        <AddPrevilige onClose={closeAddPreviliage}></AddPrevilige>
      )}
      {editData && (
        <AddPrevilige
          onClose={closeUpdatePreviliage}
          data={selectedData}
        ></AddPrevilige>
      )}
      {!(canShowForm || editData) && (
        <div className="row">
          <div className="col-12">
            <div className="row">
              <div className="col-12 text-end d-flex justify-content-between pl-8">
                <h4 className="primary-color fw-bold mt-3">Business Units</h4>
              </div>
            </div>
            <div className="row">
              {/* <Search customClass={true} /> */}
              <div className="col-md-4 my-3">
                <input
                  type="search"
                  className="form-control p-2"
                  placeholder="Search"
                  value={searchText}
                  onChange={(e) => handleInput(e.target.value)}
                />
              </div>
              <div className="col-md-3 my-3">
                {/* <button className="btn btn-primary" onClick={handleSearch}>
                  Search
                </button>
                &nbsp; */}
                {/* <button className="btn btn-secondary" onClick={handleClear}>
                  Clear
                </button> */}
              </div>
              <div className="col-md-5 my-3 text-end">
                <button
                  className="btn btn-primary"
                  onClick={() => setCanShowForm(true)}
                >
                  Add
                </button>
                &nbsp;
              </div>
            </div>
            <div className="row ">
              <div className="col-12 px-0">
                {loading && (
                  <div className="text-center p-5">
                    <div className="spinner-border" role="status">
                      <span className="sr-only"></span>
                    </div>
                  </div>
                )}
                {!loading && (
                  <>
                    <DataTable
                      tableData={previligesData}
                      TableCols={PreviligesDataGridCols}
                      onActions={(e: any) => onActions(e)}
                    />
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
