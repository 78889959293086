import React, { SyntheticEvent, useEffect, useState } from "react";
import { MenuSystemItem } from "../../../app/model/menu-system-item";
import { ApisService } from "../../../app/core/apis.service";
import { dataDecrypt, encrypt } from "../../../app/utility/encryption-helper";
import { MenuSystemUpdates } from "../../../app/core/menu-system-updates";
import { MenuSystemUpdateItem } from "../../../app/model/menu-system-update-item";
import { MenuSystemItemView } from "./menu-system-item-view";
import toast from "react-hot-toast";

interface Props {
  type: "system" | "bu";
  bu?: string;
}
export const MenuSystem = (props: Props) => {
  const [menuSystemData, setMenuSystemData] = useState<MenuSystemItem[]>([]);
  const [buFeatures, setBuFeatures] = useState<string[]>([]);
  const [canShowScuccess, setCanShowScuccess] = useState(false);
  const [totalKeys, setTotalKeys] = useState(0);
  const [searchText, setSearchText] = useState("");
  const [isLoaded, setIsLoaded] = useState(false);
  const [originalPreviligesData, setOriginalPreviligesData] = useState<any>([]);
  const [isReady, setIsReady] = useState(false);

  useEffect(() => {
    loadMenuSystem();
    return () => {
      setMenuSystemData([]);
    }
  }, []);

  useEffect(() => {
    if (menuSystemData && menuSystemData.length) {
      if (props.type === "system") {
        prepareMenuSystemUpdate();
        // setIsLoaded(true);
      }
    }
  }, [menuSystemData]);

  useEffect(() => {
    if (menuSystemData && menuSystemData.length) {
      prepareMenuSystemUpdate();
      // setIsLoaded(true);
    }
  }, [buFeatures]);

  const loadMenuSystem = async () => {
    setIsLoaded(true);
    setIsReady(false);
    try {
      const res = await ApisService.getOrganizationData("getOrgMenuSystem", "");
      setIsLoaded(false);
      setIsReady(true);
      if (res.data && res.data.data) {
        const menuData = JSON.parse(dataDecrypt(res.data.data));
        console.log(menuData, "menuData");
        setMenuSystemData(menuData.menus);
        setOriginalPreviligesData(menuData.menus)
        if (props.bu) {
          try {
            const buRes = await ApisService.getOrganizationData(
              "findById",
              encrypt(JSON.stringify({ id: props.bu }))
            );
            const buResData = JSON.parse(dataDecrypt(buRes.data.data));
            setBuFeatures(buResData[0].features);
          } catch (error) {
            console.error("Error while loading BU Features", error);
          }
        }
      }
    } catch (error) {
      setIsLoaded(false);
      console.log(error);
    }
  };
  const onChangeVisibility = (event: SyntheticEvent, item: MenuSystemItem) => {
    const inputEl = event.target as HTMLInputElement;
    if (inputEl) {
      console.log(
        "MenuSystemUpdates.menuSystemItems",
        MenuSystemUpdates.menuSystemItems
      );

      const detectedItem = MenuSystemUpdates.menuSystemItems.find(
        (el) => el.code === item.code
      );
      if (detectedItem) {
        detectedItem.enabled = inputEl.checked;
      }
    }
  };

  const prepareMenuSystemUpdate = () => {
    setTotalKeys(0);
    const preparedMenuSystem: MenuSystemUpdateItem[] = [];
    for (let index = 0; index < menuSystemData.length; index++) {
      const element = menuSystemData[index];

      if (props.type === "system") {
        preparedMenuSystem.push({
          code: element.code,
          enabled: element.enabled,
        });
      } else if (props.type === "bu") {
        let isEnabled = buFeatures?.find((el) => el === element.code) ? true : false;
        if (!buFeatures) {
          isEnabled = element.enabled;
        }
        preparedMenuSystem.push({
          code: element.code,
          enabled: isEnabled ? true : false,
        });
      }
      if (element.children) {
        prepareInnerMenuItem(element.children, preparedMenuSystem);
      }
      setTotalKeys((prev) => prev + 1);
    }
    MenuSystemUpdates.menuSystemItems = preparedMenuSystem;
    console.log("prepared ", MenuSystemUpdates.menuSystemItems);
  };

  const prepareInnerMenuItem = (
    menuItems: MenuSystemItem[],
    preparedMenuSystem: MenuSystemUpdateItem[]
  ) => {
    for (let index = 0; index < menuItems.length; index++) {
      const element = menuItems[index];
      if (props.type === "system") {
        preparedMenuSystem.push({
          code: element.code,
          enabled: element.enabled,
        });
      } else if (props.type === "bu") {
        let isEnabled = buFeatures?.find((el) => el === element.code) ? true : false;
        if (!buFeatures) {
          isEnabled = element.enabled;
        }

        preparedMenuSystem.push({
          code: element.code,
          enabled: isEnabled ? true : false,
        });
      }
      if (element.children) {
        prepareInnerMenuItem(element.children, preparedMenuSystem);
      }
      setTotalKeys((prev) => prev + 1);
    }
  };

  const onSaveSystem = async () => {
    setIsLoaded(true);
    if (props.type === "system") {
      const menuData = encrypt(
        JSON.stringify(MenuSystemUpdates.menuSystemItems)
      );
      try {
        await ApisService.getOrganizationData("updateOrgMenuSystem", menuData);
        setIsLoaded(false);
        toast.success("Saved successfully");
      } catch (error) {
        console.log(error);
        setIsLoaded(false);
      }
    } else if (props.type === "bu") {
      const orgFeatureReq = {
        bu: props.bu,
        features: MenuSystemUpdates.menuSystemItems
          .filter((el) => el.enabled)
          .map((el) => el.code),
      };
      const menuData = encrypt(JSON.stringify(orgFeatureReq));
      console.log("org feature data ", orgFeatureReq, props);
      try {
        await ApisService.getOrganizationData("updateOrgFeatures", menuData);
        setIsLoaded(false);
        toast.success("Saved successfully");
      } catch (error) {
        console.log(error);
        setIsLoaded(false);
      }
    }
    setCanShowScuccess(true);
  };

  const mergeFeatures = () => {
    console.log("mergeFeatures");
  };


  const handleInput = (value: string) => {
    setSearchText(value);
    const filterRecursive = (data: any[], searchTerm: string): any[] => {
      return data
        .map((item) => {
          const matches =
            item?.label?.toLowerCase().includes(searchTerm.toLowerCase()) ||
            item?.code?.toLowerCase().includes(searchTerm.toLowerCase()) ||
            item?.type?.toLowerCase().includes(searchTerm.toLowerCase());
          const filteredChildren = item.children
            ? filterRecursive(item.children, searchTerm)
            : [];
          if (matches || filteredChildren.length > 0) {
            return { ...item, children: filteredChildren };
          }
          return null;
        })
        .filter(Boolean);
    };

    if (value.trim() === "") {
      setMenuSystemData(originalPreviligesData);
    } else {
      const filtered = filterRecursive(originalPreviligesData, value);
      setMenuSystemData(filtered);
    }
  };


  return (
    <div className="p-5 content-bg-color">
      {isLoaded && (
        <div className="text-center p-5">
          <div className="spinner-border" role="status">
            <span className="sr-only"></span>
          </div>
        </div>
      )}
      {!isLoaded && isReady && (
        <div>
          {/* <h5>Master Menu</h5> */}
          <div className="row mb-2 align-items">
            <div className="col-md-4">
              <div>Search</div>
              <input type="search" className="form-control p-2" placeholder="Search" value={searchText} onChange={(e) => handleInput(e.target.value)} />
            </div>
            {/* <div className="col-md-8 text-end">
              <div>
                <button className="btn btn-primary" onClick={onSaveSystem}>
                  Save
                </button>
              </div>
            </div> */}
          </div>
          {/* <hr/> */}
          <b>Total Keys:</b> {totalKeys}
          {menuSystemData?.map((el: any, dIndex: any) => (
            <>
              {(el.enabled || props.type === "system") && (
                <div className="ms-3" key={`${el.code}_${props.type}`}>
                  <input
                    type="checkbox"
                    name=""
                    id=""
                    className="me-3"
                    onClick={(event) => onChangeVisibility(event, el)}
                    defaultChecked={el?.enabled}
                  />
                  el?.enabled: {el?.enabled}
                  <span>{el.label} <small className='text-primary'>({el.code})</small></span>
                  {el.children &&
                    el.children.map((childEl: any) => (
                      <MenuSystemItemView
                        type={props.type}
                        key={`${childEl.code}_${props.type}`}
                        item={childEl}
                      ></MenuSystemItemView>
                    ))}
                </div>
              )}
            </>
          ))}
          {/* {canShowScuccess && (
            <div className="text-success text-center">Saved successfully</div>
          )} */}
          <div className="text-end">
            <button className="btn btn-primary" onClick={onSaveSystem}>
              Save
            </button>
          </div>
        </div>
      )}
    </div>
  );
};
