import React, { SyntheticEvent, useEffect, useState } from 'react'
import { MenuSystemItem } from '../../../app/model/menu-system-item'
import { MenuSystemUpdates } from '../../../app/core/menu-system-updates';

interface Props {
    item: MenuSystemItem;
    type: 'system' | 'bu';
}
export const MenuSystemItemView = (props: Props) => {
    const [initialState, setInitialState] = useState(true);
    const [isLoaded, setIsLoaded] = useState(false);

    useEffect(() => {
        const detectedItem = MenuSystemUpdates.menuSystemItems?.find(el => el.code === props.item.code);
        if (detectedItem) {
            setInitialState(detectedItem.enabled);
        }
        setIsLoaded(true);
    }, []);

    const onChangeVisibility = (event: SyntheticEvent) => {
        const inputEl = event.target as HTMLInputElement;
        if (inputEl) {
            const detectedItem = MenuSystemUpdates.menuSystemItems?.find(el => el.code === props.item.code);
            if (detectedItem) {
                detectedItem.enabled = inputEl.checked;
            }
        }
    }

    return (
        <>
            {
                isLoaded && <div className='ms-5'>
                    <input type="checkbox" name="" id="" className='me-3' onClick={(event) => onChangeVisibility(event)} defaultChecked={initialState} />
                    <span>{props.item.label} <small className='text-primary'>({props.item.code})</small></span>
                    {
                        props.item.children && props.item.children.map(childEl => <MenuSystemItemView type={props.type} key={`${childEl.code}_${props.type}`} item={childEl}></MenuSystemItemView>)
                    }
                </div>
            }
        </>
    )
}
